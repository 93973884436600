
/* You can add global styles to this file, and also import other style files */

/* === Plugin styles === */

// @import "~react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
// @import '~@fullcalendar/core/main.css';
// @import '~@fullcalendar/daygrid/main.css';
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200&display=swap');

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "../assets/styles/variables";
@import "~compass-mixins/lib/compass";
@import "~compass-mixins/lib/animate";
@import "~bootstrap/scss/bootstrap";
@import "../assets/styles/fonts";
@import "../assets/styles/functions";

/* === Icon fonts === */
// @import "~@mdi/font/scss/materialdesignicons";
// @import "~flag-icon-css/sass/flag-icon";


/* === Template mixins === */
@import "../assets/styles/mixins/animation";
@import "../assets/styles/mixins/badges";
@import "../assets/styles/mixins/buttons";
@import "../assets/styles/mixins/misc";
@import "../assets/styles/mixins/color-functions";
@import "../assets/styles/mixins/cards";
@import "../assets/styles/mixins/blockqoute";
@import "../assets/styles/mixins/popovers";
@import "../assets/styles/mixins/tooltips";
@import "../assets/styles/mixins/no-ui-slider";


/* === Core Styles === */
@import "../assets/styles/background";
@import "../assets/styles/typography";
@import "../assets/styles/reset";
@import "../assets/styles/responsive";
@import "../assets/styles/misc";
@import "../assets/styles/utilities";
@import "../assets/styles/demo";
@import "../assets/styles/spinner";
@import "../assets/styles/dashboard";

/* === Components === */

@import "../assets/styles/components/forms";
@import "../assets/styles/components/checkbox-radio";
@import "../assets/styles/components/icons";
@import "../assets/styles/components/tables";
@import "../assets/styles/components/buttons";
@import "../assets/styles/components/breadcrumbs";
@import "../assets/styles/components/cards";
@import "../assets/styles/components/preview";
@import "../assets/styles/components/user-profile";
@import "../assets/styles/components/lists";
@import "../assets/styles/components/bootstrap-progress";
@import "../assets/styles/components/tabs";
@import "../assets/styles/components/dropdowns";
@import "../assets/styles/components/pagination";
@import "../assets/styles/components/loaders/loaders";
@import "../assets/styles/components/timeline";
@import "../assets/styles/components/spinner";
@import "../assets/styles/components/react-table";

@import "../assets/styles/components/landing-screens/auth";

/* === Plugin Overrides === */
@import "../assets/styles/components/plugin-overrides/react-bootstrap-table";
@import "../assets/styles/components/plugin-overrides/react-table";

/* === Layout === */
@import "../assets/styles/navbar";
@import "../assets/styles/sidebar";
@import "../assets/styles/footer";
@import "../assets/styles/layout";
@import "../assets/styles/settings-panel";
@import url('https://fonts.googleapis.com/css?family=Muli');

//New font import start

@font-face {
    font-family: 'poppinslight';
    src: url('../assets/fonts/poppins-light-webfont.woff2') format('woff2'),
         url('../assets/fonts/poppins-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  
  }
  
  @font-face {
    font-family: 'poppinsmedium';
    src: url('../assets/fonts/poppins-medium-webfont.woff2') format('woff2'),
         url('../assets/fonts/poppins-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  
  }
  
  @font-face {
    font-family: 'poppinsregular';
    src: url('../assets/fonts/poppins-regular-webfont.woff2') format('woff2'),
         url('../assets/fonts/poppins-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  
  } 




/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
    padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

/* Set core body defaults */
body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
    list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
/* img {
    max-width: 100%;
    display: block;
} */

/* Natural flow and rhythm in articles by default */
article > * + * {
    margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}
.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
   // background-color: #1b3275 !important;
    transition: width 0.6s ease;
  }
 

body {
    // background-color: #f7f8fc !important;
    // font-family: "Source Sans Pro","Helvetica Neue",Helvetica,Arial,sans-serif !important;
    // font-family: 'Poppins', sans-serif !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;  
    line-height: 26px;
    color: #555555;
}
.menu_sub_icon
{
    margin-left: auto;
}
.sub_menu_bg
{
    background-color: #131e26;
}
@media (min-width:991px){
    .collapse:not(.show) {
        display: inherit;
    }
    .accordian.collapse:not(.show){
        display: none;
    }
    .side-menu.collapse:not(.show){
        display: none;
    }
}
.RRT__tab{    
    padding: 0 15px;
    padding: 0;
    // background-color: #fff !important;
    background-color: rgba(128, 128, 128, 0.075) !important;
    // color: #000 !important;
    position: relative;
    transition: all 0.5s ease;
    text-align: center;
    border: none !important;
    border-style: none !important;
    border-color: transparent !important;
    border-width: none !important;
    // padding: 5px 10px 5px 0 !important;
    // padding: 2px 10px 2px 0 !important;
    padding: 2px 10px 1px  !important;
    margin-left: 17px !important;
    color: #58666e !important;
    @media (max-width:767px){
        width: 100%;
        float: left;
        text-align: center;
        background-color: #ccc !important;
        color: #000;
        position: relative;
        transition: all 0.5s ease;
        &::before{
            content: "";
            width: 1px !important;
            height: 13px !important;
            background: rgba(0, 0, 0, 0.9) !important;
            position: absolute !important;
            top: 13px !important;
            right: 13px !important;
            transform: rotate(38deg);
            transition: all 0.5s ease;
        }
        &::after{
            content: "";
            width: 1px !important;
            height: 13px !important;
            background: rgba(0, 0, 0, 0.9) !important;
            position: absolute!important; 
            top: 13px !important;
            right: 21px !important;
            transform: rotate(-38deg);
            transition: all 0.5s ease;
        }
    }
}


// .RRT__tab--selected , .RRT__tab:hover{
//     background: #fff !important;
//     // color: #5a5243 !important;
//     color: rgb(88, 147, 241) !important;
//     border-radius: 2px 2px 0 0 !important;
//     margin-bottom: 0 !important;
//     padding-left: 6px !important;
//     border-color: #fff !important;
//     // border-bottom: 2px solid #8278c2 !important;
//     border-left: 4px solid rgb(88, 147, 241) !important;
//     @media (max-width:767px){
//         background-color: #7265ba !important;
//         color: #fff !important;
//         transition: all 0.5s ease;
//         border: none;
//         &::before{
//             content: "";
//             width: 1px !important;
//             height: 13px !important;
//             background: #fff !important;
//             position: absolute !important;
//             top: 13px !important;
//             right: 13px !important;
//             transform: rotate(140deg);
//             transition: all 0.5s ease;
//         }
//         &::after{
//             content: "";
//             width: 1px !important;
//             height: 13px !important;
//             background: #fff !important;
//             background-color: #fff;
//             position: absolute!important; 
//             top: 13px !important;
//             right: 21px !important;
//             transform: rotate(-140deg);
//             transition: all 0.5s ease;
//         }
//     }
// }



.RRT__tab--selected{
    background: #fff !important;
    // color: #5a5243 !important;
    color: rgb(88, 147, 241) !important;
    border-radius: 2px 2px 0 0 !important;
    margin-bottom: 0 !important;
    padding-left: 6px !important;
    border-color: #fff !important;
    // border-bottom: 2px solid #8278c2 !important;
    border-left: 3.5px solid rgb(88, 147, 241) !important;
    @media (max-width:767px){
        background-color: #7265ba !important;
        color: #fff !important;
        transition: all 0.5s ease;
        border: none;
        &::before{
            content: "";
            width: 1px !important;
            height: 13px !important;
            background: #fff !important;
            position: absolute !important;
            top: 13px !important;
            right: 13px !important;
            transform: rotate(140deg);
            transition: all 0.5s ease;
        }
        &::after{
            content: "";
            width: 1px !important;
            height: 13px !important;
            background: #fff !important;
            background-color: #fff;
            position: absolute!important; 
            top: 13px !important;
            right: 21px !important;
            transform: rotate(-140deg);
            transition: all 0.5s ease;
        }
    }
}


 .RRT__tab:hover{
    // background: #fff !important;
    border-radius: 2px 2px 0 0 !important;
    margin-bottom: 0 !important;
    // padding-left: 6px !important;
    // border-color: #fff !important;
    border-color: #5db1f9  !important;
    // border-left: 4px solid rgb(88, 147, 241) !important;
    @media (max-width:767px){
        background-color: #7265ba !important;
        color: #fff !important;
        transition: all 0.5s ease;
        border: none;
        &::before{
            content: "";
            width: 1px !important;
            height: 13px !important;
            background: #fff !important;
            position: absolute !important;
            top: 13px !important;
            right: 13px !important;
            transform: rotate(140deg);
            transition: all 0.5s ease;
        }
        &::after{
            content: "";
            width: 1px !important;
            height: 13px !important;
            background: #fff !important;
            background-color: #fff;
            position: absolute!important; 
            top: 13px !important;
            right: 21px !important;
            transform: rotate(-140deg);
            transition: all 0.5s ease;
        }
    }
}



// .RRT__accordion{
//     @media (min-width:767px){
//         flex-direction : row !important;
//     }
// }
.nav-tabs .nav-link{
    color: #a2a2a2;
}
.mdb-dataTable-head > tr > th.sorting::before,.mdb-dataTable-head > tr > th.sorting_asc::before,.mdb-dataTable-head > tr > th.sorting_desc::before{
    content: "\2191" !important;
    font-weight: 1000 !important;
}
.mdb-dataTable-head > tr > th.sorting::after,.mdb-dataTable-head > tr > th.sorting_asc::after,.mdb-dataTable-head > tr > th.sorting_desc::after{
    font-weight: 1000 !important;
    content: "\2193" !important;
}
.rdt_TableBody > div:hover{
    box-shadow: 0px 0px 14px #c7c0c082;
    -webkit-box-shadow: 0px 0px 14px #c7c0c082;
    background-color: #fff !important;
    border-bottom-color:#e0e0e0 !important;
}
.multiselect-container {
    .optionListContainer > .optionContainer > li:hover{
        background: rgb(240, 238, 238);
        color: #333;
        cursor: pointer;
    }
}
.FundTransferModal > .modal-content{
    height: 100%;
    border-radius: 0;
    background: #fff;
    padding: 20px 35px 80px;
    overflow-y: scroll;
}
.rdt_TableCell{
    div{
        &:first-child{
            white-space: normal !important;
        }
    }
}
.filepond--panel-root{
    background: none !important;
}
.filepond--root {
    width:170px;
    margin: 0 auto;
}
.react-datepicker-wrapper{
    display: block;
}
.react-datepicker-wrapper{
    display: block !important;
}
.a:focus, input:focus{
    border: 1px solid #cfdadd;;
}
.modalBackDrops{
    background-color: #3a3f51;
    &.show{
        opacity: 0.8;
    }
}
.react-datepicker-wrapper{
    width: 100%;
}
.public-DraftStyleDefault-block{
    margin: 0 !important;
}



.accordion .faq-collapse.card-header::before {
    font-family: 'FontAwesome';  
    content: "\f053";
    float: right; 
}


.accordion .faq-collapsed.card-header.collapsed::before {
    /* symbol for "collapsed" panels */
    content: "\f107"; 
}

.mtp30 {
    margin-top: 30px;
}

.form-control-xs {
    height: 2.2rem;
    padding: 0.5rem 0.81rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}
.modal-content{
    border: none;
}
.dropdown-toggle {
    background-color: transparent !important;
}
.iiHmzE{
    font-size: 15px  !important;

}
.jsAMOb{
    margin-top: 21px !important;

}

.graph_main_container{
    width: 100%;
    height: auto;
    display: inline-block;
    margin-top: 30px;
    padding: 1rem !important;
}

.graph_box_crm{
    width: 100%;
    height: auto;
    padding: 15px;
    box-shadow: 0px 0px 20px #e5e5e5;
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 15px;
}
.msg_box_cnt_t{
    width: 73%;
    float: right;
}
.sidebar-icon-only .content-wrapper{padding-left: 20px;}
.CalendarContainer-sc-1gorc7r-0 {z-index: 9;}
.hHSTiF{overflow: auto !important;height: 450px;}
.table-responsive{background-color: #fff; padding: 10px;}

.sidebar-icon-only .commonstyle_main_contant_sec__106Wq .commonstyle_topbar_section__1lK-R .commonstyle_logo_sec__5Gx-V .commonstyle_menubartogglebtn__i-OwH span{ background-color: transparent !important;}
.sidebar-icon-only .commonstyle_main_contant_sec__106Wq .commonstyle_topbar_section__1lK-R .commonstyle_logo_sec__5Gx-V .commonstyle_menubartogglebtn__i-OwH span::before{
    transform: rotate(140deg);
    bottom: 20px;
    width: 25px;
}
.sidebar-icon-only .commonstyle_main_contant_sec__106Wq .commonstyle_topbar_section__1lK-R .commonstyle_logo_sec__5Gx-V .commonstyle_menubartogglebtn__i-OwH span::after{
    bottom: 20px;
    width: 25px;
    transform: rotate(46deg);
}
.btn-primary{
    background-color: #7266ba !important;
    border-color: #7266ba !important;
}
.btn-primary:focus{
    background-color: #7266ba !important;
    border-color: #7266ba !important;
}
.btn-primary:hover{
    background-color: #7266ba !important;
    border-color: #7266ba !important;
}
.btn-info{
    background-color: #7266ba !important;
    border-color: #7266ba !important;
}
.dropdown-toggle{
    background-color: transparent !important;
    border-color: transparent !important;
}
.ilLGeD{font-size: 15px !important;font-family: 'poppinsregular';}
.table th, .table td{font-size: 15px !important;font-family: 'poppinsregular';}
p{font-size: 15px !important;font-family: 'poppinsregular';}
.fjxduL{font-size: 15px !important;font-family: 'poppinsregular';}
.cart_btnAddonInfo__1EvR0:hover{background: #19a9d5  !important;border-color: #19a9d5  !important;}

@media only screen and (max-width: 600px) {
.RRT__accordion .RRT__tab{margin-left: 0 !important;}
}

@media (max-width: 768px) {
    .commonstyle_top_notification_sec__DaQsZ .Common_langContainer__1G76E{display: none;}
    .col{width: 100%;flex-basis: 100%;}
    .card_body{padding: 0 !important;}
    .card_body .media{margin-left: 0 ;}
    .Common_formGroup__wB176{margin: 10px 0 !important;}
    .FundTransferModal > .modal-content{padding: 5px;}
    .payout_ModalDialog__1rd5W{max-width: 90% !important;width: 90% !important;}
}
@media (max-width: 500px) {
    .mail_mailboxMainContent__1u7Zj .mail_rightSide__1d49D .mail_composeMailContent__3Hhip .mail_listItems__3ZOYD .mail_content__1VSFZ .mail_type__ZHsUx{display: inline-block !important;}
   .invoice_exportButton__2JLh8 button{margin: 2px !important;    margin-top: 10px !important;}
   .payout_exportButton__3d0xT button{margin: 2px !important;    margin-top: 10px !important;    padding: 0 11px;}
   .ewallet_MainContainer__2rJlL .ewallet_summary__3f9eN .ewallet_tabContent__3KFsw .ewallet_dataTable__Ca4XA .ewallet_filterSection__8SPCW .ewallet_exportButton__3Z6Ae button{margin: 2px !important;    margin-top: 10px !important;    padding: 0 11px;}
   .card .card-body {padding: 10px;}
   .ewallet_MainContainer__2rJlL .ewallet_summary__3f9eN .ewallet_tabContent__3KFsw .ewallet_TabPanel__e7Cbx {padding: 0px;}
}

.expired-notice {
    text-align: center;
    padding: 2rem;
    border: 1px solid #ebebeb;
    border-radius: 0.25rem;
    margin: 0.5rem;
  }
  
  .expired-notice > span {
    font-size: 2.5rem;
    font-weight: bold;
    color: red;
  }
  
  .expired-notice > p {
    font-size: 1.5rem;
  }
  
  .show-counter {
    padding: 0.5rem 0;
  }
  
  .show-counter .countdown-link {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: flex-start;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.75rem;
    padding: 0rem;
    //border: 1px solid #ebebeb;
    border-radius: 0.25rem;
    text-decoration: none;
    color: #000;
  }
  
  .show-counter .countdown {
    line-height: 1.25rem;
    padding: 0 0.75rem 0 0.75rem;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  
  .show-counter .countdown.danger {
    color: #ff0000;
  }
  
  .show-counter .countdown > p {
    margin: 0;
  }
  
  .show-counter .countdown > span {
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .text-red {
    color: red;
  }
  .blink-hard {
    animation: blinker 1s step-end infinite;
  }

  .show-counter .countdown{color: #fff;}
  .show-counter .countdown > p{color: #fff;}
  .profile_other_box p strong{margin-top: -10px;}

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
  @media (max-width:991px) {
    .show-counter .countdown-link{justify-content: center;}
    .show-counter .countdown{color: #242424;}
    .show-counter .countdown > p{color: #242424;}
  }
  

  //APP


  
  body{margin: 0;padding: 0;font-family: 'poppinsregular';}
  .top_bar_section_1{
    width: 100%;
    height: auto;
    float: left;
    background-color: #00a4ff; 
    padding:10px 0;
  }
  .main_header_sec{
    width: 100%;
   // height: auto;
    float: left;
    box-shadow: 0px 1px 18px rgb(0, 0, 0 / 10%);
    position: fixed;
    left: 0;
    top: 0;
    background-color: #fff;
  }
  .main_logo{
    width: 100%;
    height: auto;
    float: left;
  }
  .main_logo img{max-width: 300px;}
  .login_btn{
    padding: 10px 30px !important;
    background-color: #00a4ff;
    color: #fff !important;
    font-size: 16px;
    border-radius: 40px;
    transition: 0.2s ease;
  }
  .login_btn:hover{background-color: #242424;}
  .banner_section{
    width: 100%;
    float: left;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100vh;
    text-align: center;
  
  }
  .top_bar_section{
    width: 100%;
    height: auto;
    float: left;
  }
  
  .top_cnt_sec{
    width: 100%;
    height: auto;
    float: left;
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .top_cnt_sec_box{
    color: #fff;
    font-size: 14px;
    margin-left: 16px;
  }
  .navbar_expand_lg .navbar-nav .nav-link{
    padding-right: 20px;
    padding-left: 20px;
    text-transform: uppercase;
    color: #242424 !important;
  }
  .menubar_sec{margin-top: 10px;}
  .banner_contant_sec{
    width: 100%;
    height: auto;
    float: left;
    margin-top: 60px;
  }
  .banner_contant_sec h3{color: #666;margin-bottom: 20px;display: inline-block;}
  .banner_button{
    width: 150px;
    height: 50px;
    background-color: transparent;
    text-align: center;
    line-height: 50px;
    color: #fff;
    margin-top: 10px;
    border-radius: 30px;
    display: inline-block;
    text-decoration: none;
    border: 1px solid;
  }
  .banner_button:hover{background-color: #fff;color:#242424;text-decoration: none;cursor: pointer;}
  .plan_cnt_sec{
    width: 100%;
    height: auto;
    float: left;
    padding:90px 0;
    background-image: url(../assets/images/dot-big-square.svg);
    background-repeat: no-repeat;
    background-position: 270px 112px;
    min-height: 400px;
  }
  .plan_cnt_sec_head{
    width: 100%;
    height: auto;
    float: left;
    text-align: center;
    color: #242424;
    margin-bottom: 30px;
    font-family: 'poppinsmedium';
  }
  .plan_cnt_sec_head h2{
    font-size: 40px;
  }
  
  .plan_box_sec{
    width: 100%;
    height: auto;
    float: left;
    padding:40px 20px;
    border-radius: 10px;
    box-shadow: 10px 3px 20px 0 rgb(235, 233, 233);
    // box-shadow: 10px 3px 20px 0 rgb(210 ,210, 245 / 50%);
    background-color: #fff;
    margin-top: 10px;
  }
  .plan_box_head{
    width: 100%;
    height: auto;
    float: left;
    text-align: center;
    color: #242424;
    font-size: 25px;
  }
  .plan_box_head h3{margin: auto; color: #242424;  font-size: 25px;}
  .plan_box_cnt{
    width: 100%;
    height: auto;
    float: left;
    text-align: center;
    font-size: 16px;
    color: #666;
    line-height: 28px;
    margin-top: 20px;
  }
  .plan_box_cnt p{
    font-size: 16px;
    color: #666;
  }
  .about_section{
    width: 100%;
    height: auto;
    float: left;
    padding: 80px 0;
    background-color: #f3f3f3;
  }
  .about_section_contant{
    width: 100%;
    height: auto;
    float: left;
  }
  .about_sectioncnt_sec_head{
    width: 100%;
    height: auto;
    float: left;
    text-align: center;
    color: #242424;
    font-size: 18px;
  }
  .about_sectioncnt_sec_head h2{
    width: 100%;
    height: auto;
    float: left;
    text-align: center;
    color: #242424;
    font-family: 'poppinsmedium';
    font-size: 40px;
  }
  .about_section_contant_head{
    width: 100%;
    height: auto;
    float: left;
    color: #242424;
    font-size: 18px;
    margin-top: 50px;
    font-family: 'poppinsmedium';
  }
  
  .about_section_contant_paragp{
    width: 100%;
    height: auto;
    float: left;
    text-align: left;
    font-size: 16px;
    color: #666;
    line-height: 28px;
    margin-top: 20px;
  }
  .about_section_contant_paragp p{
    text-align: left;
    font-size: 16px;
    color: #666;
  }
  
  .about_section_img{
    width: 100%;
    height: auto;
    float: left;
    text-align: center;
  }
  .about_section_img img{width: 80%;}
  
  .contact_section{
    width: 100%;
    height: auto;
    float: left;
    padding: 80px 0;
  }
  .contact_section_head h2{
    width: 100%;
    height: auto;
    float: left;
    color: #242424;
    font-family: 'poppinsmedium';
    font-size: 40px;
  }
  .contact_section_head{
    width: 100%;
    height: auto;
    float: left;
    color: #242424;
    font-size: 18px;
    margin-top: 50px;
    
  }
  .contact_bx_phone{
    width: 100%;
    height: auto;
    float: left; 
    margin-top: 30px;
  }
  .contact_bx_phone_ico{
    width: 40px;
    height: 40px;
    float: left;
    background-color: #d0eeff;
    border-radius: 50px;
    text-align: center;
    line-height: 40px;
    color: #242424;
  }
  .contact_bx_phone span{
    width: 100%;
    height: auto;
    float: left; 
    text-align: left;
    font-size: 18px;
    color: #242424;
    margin-top: 10px;
  }
  .contact_bx_phone strong{
    width: 100%;
    height: auto;
    float: left; 
  
  }
  .form_sec_box{
    width: 100%;
    height: auto;
    float: left; 
    padding: 20px;
    box-shadow: 5px 0.8rem 3rem rgb(0 ,0 ,0 / 15%) !important;
    background-color: #fff;
    margin-top: 30px;
  }
  .form_sec_box h4{
    width: 100%;
    height: auto;
    float: left; 
    text-align: left;
    font-size: 24px;
    color: #242424;
    margin-top: 10px;
    font-family: 'poppinsmedium';
  }
  .form_sec_box_group{
    width: 100%;
    height: auto;
    float: left; 
    margin-top: 30px;
  }
  .form_sec_box_group .group{
    width: 100%;
    height: auto;
    float: left; 
    margin-bottom: 15px;
  }
  .fooer_link{
    text-align: right;
  }
  .footer_lft{
    font-size: 14px;
  }
  .fooer_link a{
    color: #666;
    text-decoration: none;
    font-size: 14px;
    margin-left: 10px;
  }
  .footer_sec{
    width: 100%;
    height: auto;
    float: left; 
    padding: 20px 0;
    background-color: #f3f3f3;
  }
  .row{clear: both;}
  .navbar-light .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .show > .nav-link {
    color: rgba(0,0,0,.9);
  }
  .navbar_dark{
    padding-right: 20px;
    padding-left: 20px;
    text-transform: uppercase;
    color: rgba(0,0,0,.9) !important;

  }

  .headershow{
    height: 4rem;
    width: 100%;
    position: relative;
    top: 0px;
    // transition: 0.3s linear;
   // transition: top 0.1s ease-in-out;
    display: flex;
    justify-content:stretch;
    align-items: center;
    background-color: #FFFFFF;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    z-index: 40;
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .08);
    /* padding: 0 7%; */
}
.headerhidden{
    height: 4rem;
    width: 100%;
    z-index: 40;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .08);
    position: fixed;
    top: -80px;
    transition: 1.1s ease-in-out;
 

}

.replica_top_cnt_sec_box__2VOZh .Common_langContainer__M0jOp .Common_selectedLang__2mIhA{padding: 0 !important;}

.mob_full_sec .navbar{left: 0;padding-right: 0;}
.replica_main_header_sec__3Xx7k .navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 20px;
  padding-left: 20px;
  cursor: pointer;
  padding: 10px 30px !important;
}
.replica_main_header_sec__3Xx7k .navbar-expand-lg .navbar-nav .nav-link:hover{background-color: #7266ba;color: #fff;border-radius: 40px;}
.plan_cnt_sec img{width: 100%;}
.aboutclass img{width: 100%;}

.aboutclass{
  width: 100%;
  height: auto;
  display: inline-block;
}

@media (max-width:1190px){
 .fll_wdth_row_tab{width: 100% !important;    display: inline-block;}

}
@media (max-width:991px){
    .mob_full_sec{position: absolute;right: 0;width: 100%;max-width: 100%;}
    .navbar_toggler{position: absolute; top: 14px; right: 11px;}
    .navbar-collapse{align-items: center; background-color: #fff; margin-top: 67px; box-shadow: 0px 0px 10px #00000029; padding: 20px;}
    .fooer_link{text-align: center;margin-top: 10px;}
    .footer_lft{text-align: center;}
    .main-panel{padding-top: 130px;}
    .sidebar{background: #243040;}
    .mob_full_sec .navbar{height: auto !important;padding-right: 20px;border: 0;}
    .mob_full_sec .navbar-toggler-icon{position: absolute; right: 10px; left: inherit; top: 19px;}
    .main_logo{min-height: 60px;}
    .mob_full_sec .navbar-toggler-icon:before{content: "\f0c9";font: normal normal normal 17px/1 FontAwesome; }
    .mob_full_sec .navbar li{margin-bottom: 5px;}
}
@media (max-width:768px){
    .main_logo{max-width: 200px;padding: 10px 0;}
    .main_logo img{width: 100%;}
    .fll_wdth_row_tab{width: 102% !important;}
}
@media (max-width:500px){
    .top_cnt_sec{display: inline-block;  text-align: center;}
}


//Replica
.heading{font-size:40px !important}
.subheading{font-size:18px;font-weight:500;padding-bottom:60px}

